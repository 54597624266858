@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
body {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
}

button,
a {
  color: inherit;
}

.logo {
  max-width: 4em;
}

.slick-next:before,
.slick-prev:before {
  color: #000 !important;
}

.qrockme-action.qrockme-action-checkpoint {
  margin: 0;
  overflow: hidden;
  height: 0;
}

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input,
textarea {
  -moz-user-select: text !important;
   -ms-user-select: text !important;
       user-select: text !important;
  -webkit-touch-callout: default !important;
  -webkit-user-select: text !important;
}

*::-moz-placeholder {
  color: #555;
  opacity: 1;
}

*:-ms-input-placeholder {
  color: #555;
}

*::-webkit-input-placeholder {
  color: #555;
}

